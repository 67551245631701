.menu-item-card {
  min-height: 115px;
}

.menu-item-name {
  font-weight: bold;
  font-size: 13px;
}

.menu-item-price {
  font-size: 12px;
}

.menu-item-description {
  font-size: 12px;
  color: grey;
}

.menu-item-detail-toggle {
  font-size: 12px;
  color: var(--primary);
  cursor: pointer;
}

.menu-category-header {
  font-size: 24px;
  cursor: pointer;
}

.menu-category-header:hover {
  color: var(--primary);
}

#back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99999;
}

.hide {
  display: none !important;
}