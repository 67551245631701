@media only screen and (max-width: 992px) {
  /* #logo img {
    max-width: calc(100% - 75px);
    max-height: 80px;
  } */
}

/* @media only screen and (min-width: 992px) { */
#navbar {
  flex-direction: column;
}

/* #navbar #nav {
    padding: 0.5em 1em;
    border-bottom: 2px solid #000;
  } */
/* } */

/* #navbar #nav {
  margin-top: 0.5em;
  border-top: 2px solid #000;
} */

.footer-text {
  font-size: 0.8em;
}
